import React from "react"
import Whoarewe from "../../assets/Whoarewe.webp"
import "./WhoAreWe.scss"

const WhatWeDo = () => {
	return (
		<>
			<section
				className='whoarewe'
				style={{ justifyContent: "flex-start", paddingTop: "0rem" }}
			>
				<div className='whoarewe__right'>
					<p className='whoarewe__right--text'>
						💡 NFT Commerce: You have your NFTs created. What’s next? Squirrel
						is here to help with:
						<ul style={{ marginLeft: "2rem" }}>
							<li>Primary Sales</li>
							<li>Secondary sales</li>
							<li>Bidding</li>
							<li>Royalty</li>
							<li>Crypto & fiat payments</li>
						</ul>
					</p>
					<p className='whoarewe__right--text' style={{ marginTop: "2rem" }}>
						🤝 Engagement Module: Engagement is the core part of Web3 and NFT
						technologies. Let Squirrel help you with that:
						<ul style={{ marginLeft: "2rem" }}>
							<li>Deliver & track utilities</li>
							<li>Personalization</li>
							<li>Loyalty programs</li>
						</ul>
					</p>
				</div>
				<div className='whoarewe__left'>
					<p className='whoarewe__left--text'>
						🔁 Digital Asset Lifecycle Management - Squirrel helps you Create
						and manage your Digital Assets with the following solutions:
						<ul style={{ marginLeft: "2rem" }}>
							<li>Asset hierarchy</li>
							<li>Design collections and utilities</li>
							<li>Mint/create virtual assets</li>
							<li>Track asset inventory</li>
							<li>Pricing and royalty setup</li>
							<li>Web2 Integrations</li>
						</ul>
					</p>
					<p className='whoarewe__left--text'>
						<span>📊</span> Analytics: You have started rolling out. How do you
						measure success? Get analytics like
						<ul style={{ marginLeft: "2rem" }}>
							<li> Customer, Sales </li>
							<li> Inventory data insights, on and off Chain </li>
							<li> Web2 data hooks, and more with Squirrel Analytics. </li>
						</ul>
					</p>
				</div>
			</section>
		</>
	)
}

export default WhatWeDo
