import React from "react"
import float from "../../assets/float.webp"
import "./Landing.scss"

const Landing = () => (
	<div className='gpt3__landing' id='meta'>
		<div className='gpt3__landing-content'>
			<h1 className='gradient__text' style={{ fontSize: "2.5rem" }}>
				<span>Our Solution - Metascreen</span> <br /> India's First NFT as a
				Movie Ticket
			</h1>
			<p>
				Watch Mithila Makhaan, first ever Maithili Film to win a national award
				by government of India.
			</p>

			<div
				className='gpt3__landing-content__input'
				style={{ justifyContent: "center" }}
			>
				{/* <input type="email" placeholder="Your Email Address" /> */}
				<a
					href='https://metascreen.squirrelverse.io'
					target='__blank'
					className='button'
					type='button'
				>
					Know in Detail
				</a>
			</div>
			{/* 
      <div className="gpt3__landing-content__people">
        <img src={people} />
        <p>1,600 people requested access a visit in last 24 hours</p>
      </div>  */}
		</div>

		<div className='gpt3__landing-image'>
			<img className='float' src={float} alt='squirrel-logo' />
		</div>
	</div>
)

export default Landing
