import React from "react"
import squirrel from "../../assets/squirrel-min.webp"
import "./header.scss"

const Header = () => (
	<div className='gpt3__header' id='home'>
		<div className='gpt3__header-content'>
			<h1 className='gradient__text'>
				Transforming your customer engagement through NFTs
			</h1>
			<p className="header_p">
				Consumer Brands are making use of NFTs and Web3 technologies to
				transform their consumer experience and enhance their communication.
				This shows them massive growth results. Excited to use NFTs for your
				benefit too, but don’t know where to start? Squirrel helps you take the
				first step.
			</p>
			<p className="header_p">
				Squirrel offers World's 1st Enterprise NFT solution. The next-gen
				Enterprise NFT solution to open new growth opportunities in the new era
				of the internet.
			</p>
			<div className='gpt3__landing-content__input'>
				{/* <input type="email" placeholder="Your Email Address" /> */}
				<a href='#contact' className='button' type='button'>
					Contact Us
				</a>
			</div>

			{/*<div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Get Started</button>
</div>*/}

			{/* <div className="gpt3__header-content__people">
        <img src={people} />
        <p>1,600 people requested access a visit in last 24 hours</p>
      </div> */}
		</div>

		<div className='gpt3__header-image'>
			<img className='float' src={squirrel} alt='squirrel-logo' />
		</div>
	</div>
)

export default Header
