import React from "react"
import "./WhySquirrel.scss"

const WhySquirrel = () => {
	return (
		<div className='whysquirrel'>
			<h1 className='whysquirrel__title gradient__text'>
				Why choose SquirrelVerse?
			</h1>
			<p className='whysquirrel__text'>
				We are betting on Web3 becoming the future of internet.
			</p>
			<p className='whysquirrel__text whysquirrel__text-2'>
				"Web3 will be the internet co-owned by users and brands orchestrated by
				digital tokens."
			</p>
		</div>
	)
}

export default WhySquirrel
