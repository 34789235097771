import React from "react";

import "./modal.scss";

const Modal = ({ setIsOpen }) => {
	return (
		<div className='backdrop'>
			<div className='errmodal'>
				<div className='errmodal__header'>
					<h2>Acknowledgment</h2>
				</div>
				<div className='errmodal__main'>
					<p>Thanks, we have received your message</p>
					
				</div>
				<footer className='errmodal__footer'>
					
					<a className="errmodal__btn1 bg-purple " onClick={() => setIsOpen(false)}>Close</a>
				</footer>
			</div>
		</div>
	);
};

export default Modal;