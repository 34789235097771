import {
	FcGlobe,
	FcMultipleDevices,
	FcTemplate,
	FcMoneyTransfer,
	FcPhotoReel,
} from "react-icons/fc"
export const provides_data = [
	{
		icon: <FcTemplate />,
		title: "Digital proof of ownership",
		text: "You can instantly have proof of ownership of any asset by connecting your wallet.",
	},
	{
		icon: <FcPhotoReel />,
		title: "Digital collection",
		text: "Have collectibles that can never be copied or forged.",
	},
	{
		icon: <FcMoneyTransfer />,
		title: "Transfer of value",
		text: "Transfer of value/earnings becomes highly secure.",
	},
	{
		icon: <FcGlobe />,
		title: "Interoperability",
		text: "A single dynamic loyalty card giving access to various brands as well as partner offers without the need for additional verifications.",
	},
	{
		icon: <FcMultipleDevices />,
		title: "Accessibility",
		text: "Anyone from anywhere can create a wallet on public blockchains",
	},
]
