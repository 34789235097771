import React from "react"
import "./IconsSpace.scss"

const IconsSpace = (props) => {
	const { title, data } = props

	return (
		<section className='iconspace__container'>
			<h1 className='iconspace__container--title gradient__text'>{title}</h1>
			<div className='iconspace'>
				{data.map((item, index) => (
					<div className='iconspace__item' key={index}>
						<div className='iconspace__item__icon'>{item.icon}</div>
						<div className='iconspace__item__title'>{item.title}</div>
						<div className='iconspace__item__text'>{item.text}</div>
					</div>
				))}
			</div>
		</section>
	)
}

export default IconsSpace
