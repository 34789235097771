import React, { useState } from "react"
import { RiMenu3Line, RiCloseLine } from "react-icons/ri"
import { Link } from "react-router-dom"
import logo from "../../logo.svg"
import "./AboutNav.scss"
import Squirrel_Logo from "../../assets/Squirrel_Logo.png"

const Navbar = () => {
	const [toggleMenu, setToggleMenu] = useState(false)

	return (
		<div className='aboutnav__navbar'>
			<div className='aboutnav__navbar-links'>
				<div className='aboutnav__navbar-links_logo'>
					<img src={Squirrel_Logo} />
				</div>
				<div className='aboutnav__navbar-links_container'>
					<p>
						<Link to='/'>Home</Link>
					</p>
					<p>
						<Link to='/about'>About</Link>
					</p>
					<p>
						<a href='#features'>Solutions</a>
					</p>
					{/* <p>
						<a href='#team'>Team</a>
					</p> */}
					<p>
						<a href='#resources'>Resources</a>
					</p>
					<p>
						<a href='#contact'>Contact</a>
					</p>
				</div>
			</div>
			<div className='aboutnav__navbar-sign'></div>
			<div className='aboutnav__navbar-menu'>
				{toggleMenu ? (
					<RiCloseLine
						color='#fff'
						size={27}
						onClick={() => setToggleMenu(false)}
					/>
				) : (
					<RiMenu3Line
						color='#fff'
						size={27}
						onClick={() => setToggleMenu(true)}
					/>
				)}
				{toggleMenu && (
					<div className='aboutnav__navbar-menu_container scale-up-center'>
						<div className='aboutnav__navbar-menu_container-links'>
							<p>
								<Link to='/'>Home</Link>
							</p>
							<p>
								<Link to='/about'>About</Link>
							</p>
							<p>
								<a href='#features'>Solutions</a>
							</p>
							{/* <p>
								<a href='#team'>Team</a>
							</p> */}
							<p>
								<a href='#resources'>Resources</a>
							</p>
							<p>
								<a href='#contact'>Contact</a>
							</p>
						</div>
						<div className='aboutnav__navbar-menu_container-links-sign'></div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Navbar
