import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { AboutUs } from "./containers"

const LazyMain = React.lazy(() => import("./Main"))
const LazyAbout = React.lazy(() => import("./containers/AboutUs/AboutUs"))

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path='/'
					element={
						<React.Suspense fallback='Loading..'>
							<LazyMain />
						</React.Suspense>
					}
				/>
				<Route
					path='/about'
					element={
						<React.Suspense fallback='Loading..'>
							<LazyAbout />
						</React.Suspense>
					}
				/>
				{/* <Route path='/about' element={<AboutUs />} /> */}
			</Routes>
		</BrowserRouter>
	)
}

export default App
