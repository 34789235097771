import React from "react"
import { whatHelp } from "./what-help"
import IconsSpace from "../IconsSpace/IconsSpace"
import SwiperSlides from "../../components/SwiperSlides/SwiperSlides"
import Whoarewe from "../../assets/Whoarewe.webp"

import WhatWeDo from "./WhatWeDo"
import "./WhoAreWe.scss"

const WhoAreWe = () => {
	return (
		<>
			<section className='whoarewe'>
				<div className='whoarewe__right '>
					<h1 className='whoarewe__right--title gradient__text'>Who are we?</h1>
					<p className='whoarewe__right--text'>
						Squirrel is your Go-To Partner to bring value to your brand through
						NFTs. We are the World's 1st Enterprise NFT solution provider to
						help brands leverage NFT technology and keep the first step in the
						new era.
					</p>
				</div>
				<div className='whoarewe__left'>
					<img
						className='whoarewe__left--img'
						src={Whoarewe}
						alt='Who are we?'
					/>
				</div>
			</section>
			{/* <IconsSpace
				data={whatHelp}
				title='We help you to fill the gap between you and Web3: '
			/> */}
			<h1 className='what_title'>What do we do?</h1>
			<WhatWeDo />
			<div className='wehelp'>
				<h1 className='wehelp--title gradient__text'>
					We help you to fill the gap between you and Web3.
				</h1>
				<SwiperSlides />
			</div>
		</>
	)
}

export default WhoAreWe
