import React from "react";
import "./featureHeader.css";

const FeatureHeader = ({ title, text }) => (
	<div className='gpt3__features-container__featureHeader'>
		<div className='gpt3__features-container__feature-htitle'>
			<div />
			<h1 className='gradient__text'>{title}</h1>
		</div>
		<div className='gpt3__features-container_feature-htext'>
			<p>{text}</p>
		</div>
	</div>
);

export default FeatureHeader;
