import React from "react"
import { AboutNav } from "../../components"
import { FaAngleDoubleRight } from "react-icons/fa"
import { FcNext } from "react-icons/fc"
import "./AboutUs.scss"

const AboutUs = () => {
	return (
		<section className='aboutus'>
			<div className='aboutus__home'>
				<AboutNav />
				<div className='aboutus__home__container'>
					<h2 className='aboutus__home__container--title'>About Us</h2>

					<p className='aboutus__home__container--text'>
						<FcNext /> Working for more than 20 years in enterprise software and
						consulting, team SquirrelVerse loves helping businesses thrive and
						grow with the ever-changing world.{" "}
					</p>
					<p className='aboutus__home__container--text'>
						<FcNext /> Along with their team, who are passionate about helping
						brands adopt the latest in commerce and engagement.
					</p>
					<p className='aboutus__home__container--text'>
						<FcNext /> What joined them to form SquirrelVerse is their
						enthusiasm about Web3 and how it will change the technology world
						for brands in the near future.
					</p>
					<p className='aboutus__home__container--text'>
						<FcNext /> We see the challenge businesses can face while adopting
						Web3, and SquirrelVerse presents solutions that help you easily
						onboard and make the best out of the new world of the Internet.
					</p>
					<p className='aboutus__home__container--text'>
						<FcNext /> We are thrilled to bring the new opportunities of Web3 to
						you, and thus we have built the Metaverse Enterprise Software with
						consulting solutions to give your brand a competitive edge. Our
						purpose is to help you get an early mover advantage and discover new
						possibilities for becoming the go-to brand for your consumers.
					</p>
					<p className='aboutus__home__container--text'>
						<FcNext /> The Metaverse is here, and it’s not only transforming how
						we see the world but how we participate in it – from the factory
						floor to the meeting room.” - Satya Nadella
					</p>
				</div>
				{/* <h1 className='aboutus__home--title gradient__text'>
					SquirrelVerse: Your portal into Metaverse
				</h1> */}
			</div>
		</section>
	)
}

export default AboutUs
