import { FcReading, FcCheckmark, FcDebt, FcSafe } from "react-icons/fc"

export const whatHelp = [
	{
		icon: <FcReading />,
		title: "Knowledge Barrier",
		text: "Easily understand what Web3 and digital assets are. Better understanding helps you make better decisions. ",
	},
	{
		icon: <FcCheckmark />,
		title: "Web2 Fitment",
		text: "Worried about trying new technologies? We provide solutions integrating Web2 and Web3.  ",
	},
	{
		icon: <FcDebt />,
		title: "Uncertain ROI",
		text: "We know the world’s still prepping, and there are fewer case studies. We consult, handhold, and provide useful tools.",
	},
	{
		icon: <FcSafe />,
		title: "Safety and Security",
		text: "We know what concerns you. SquirrelVerse provides utility-based tools for different verticles",
	},
]
