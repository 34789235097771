import React, { useRef, useState } from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

import { EffectCards, Keyboard, Pagination, Navigation } from "swiper"
// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-cards"

import "./SwiperSlides.scss"
import { whatHelp } from "../../containers/WhoAreWe/what-help"

// import required modules

export default function SwiperSlides() {
	return (
		<div className='swiperslide'>
			<Swiper
				effect={"cards"}
				grabCursor={true}
				pagination={{
          clickable: true,
        }}
        navigation={true}
				modules={[EffectCards, Pagination, Navigation, Keyboard]}
				className='mySwiper swiperC'
			>
				{whatHelp.map((item, index) => (
					<SwiperSlide className='swiper-slideC' key={index}>
						<div className='swiperslider__item'>
							<div className='swiperslider__item__icon'>{item.icon}</div>
							<div className='swiperslider__item__title'>{item.title}</div>
							<div className='swiperslider__item__desc'>{item.text}</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}
