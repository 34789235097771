import React from "react"
import Feature from "../../components/feature/Feature"
import FeatureHeader from "../../components/featureHeader/FeatureHeader"
import "./whatGPT3.css"

const WhatGPT3 = () => (
	<div className='gpt3__whatgpt3 section__margin' id='About'>
		<div className='gpt3__whatgpt3-feature'>
			<FeatureHeader
				title='Our USP'
				text='Our mission at SquirrelVerse is to be the trusted Web3 innovation and adoption partner.'
			/>
		</div>
		{/* <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p>Explore the Library</p>
    </div> */}
		<div className='gpt3__whatgpt3-container'>
			<Feature text='Bridge gap between Web2 and Web3 - Worried about trying new technologies? We provide solutions integrating Web2 and Web3. ' />
			<Feature text='Fill Your Web3 knowledge barrier - We help you easily understand what NFT and Web3 are. Better understanding helps you make better decisions.' />
			<Feature text='Uncertain ROI - We know the world’s still prepping, and there are fewer case studies. We consult, handhold, and provide useful tools.' />
			<Feature text='Safety and Security - We know what concerns you. Squirrel provides utility-based tools for different verticles.' />
		</div>
	</div>
)

export default WhatGPT3
