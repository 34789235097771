import React from "react";
import { BsInstagram, BsLinkedin, BsTwitter, BsMedium } from "react-icons/bs";
import gpt3Logo from "../../logo.svg";
import "./footer.scss";
import Squirrel_Logo from "../../assets/Squirrel_Logo.png";
const Footer = () => (
	<footer className='footer'>
		<div className='footer__social'>
			<p className='footer__social--head'>Follow us on</p>
			<a
				target='_blank'
				rel='noreferrer'
				href='https://twitter.com/Squirrel_web3'
			>
				<BsTwitter />
			</a>
			<a
				target='_blank'
				rel='noreferrer'
				href='https://www.linkedin.com/company/squirrel-web3/'
				className=''
			>
				<BsLinkedin />
			</a>
			<a
				target='_blank'
				rel='noreferrer'
				href='https://www.instagram.com/metascreen_nft/'
				className=''
			>
				<BsInstagram />
			</a>
			<a
				target='_blank'
				rel='noreferrer'
				href='https://medium.com/@SquirrelVerse'
				className=''
			>
				<BsMedium />
			</a>
		</div>
		<div className='footer__pages'>
			<ul>
				<li>
					<a target='_blank' rel='noreferrer' href='/'>
						Home
					</a>
				</li>
				{/* <li>
					<a rel='noreferrer' href='#resources'>
						Resources
					</a>
				</li> */}
				<li>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://metascreen.squirrelverse.io/terms-and-conditions'
					>
						Terms & Conditions
					</a>
				</li>
				<li>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://metascreen.squirrelverse.io/privacy-policy'
					>
						Privacy Policy
					</a>
				</li>
			</ul>
		</div>

		<p className='border-line' />
		<div className='footer__copy'>
			<p className='footer__copy--left'>Copyright © 2022 squirrel verse llc </p>
			<p className='footer__copy--right'> All rights Reserved</p>
		</div>
		<a target='_blank' rel='noreferrer' href='/'>
			<img src={Squirrel_Logo} alt='Squirrel logo' />
		</a>
	</footer>
);

export default Footer;
