import React,{useState} from "react";
import "./Contact.css";
import emailjs from "emailjs-com";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Modal from "../../components/Modal/Modal";

const Contact = () => {
	const [isOpen, setIsOpen] = useState(false);


	function sendEmail(event){
		event.preventDefault();
		console.log(event.target[1].value);
		const data = {
			from_name: event.target[0].value,
			email: event.target[1].value,
			message: event.target[2].value,
		};
		console.log("Inside mail");
		//console.log(res.current);
		emailjs.init("_V7kq_Qw-tQ22YO7h");
		emailjs.send("service_b5f6a6r", "template_dpfe7dg", data).then(
			(result) => {
				console.log(result.text);
			},
			(error) => {
				console.log(error.text);
			}
		);
		/*confirmAlert({
			//title: 'Acknowledgement',
			message: 'Thanks, we have received your message',
			buttons: [
			  {
				label: 'Close',
				//onClick: () => alert('Click Yes')
			  }
			  
			]
		  });*/

		  setIsOpen(true);

		  event.target.reset();
	}
	return (
		<div className='contact' id='contact'>
			<div class='contact__container'>
				<div class='content'>
					<div class='left-side'>
						<div class='email details'>
							<i class='fas fa-envelope'></i>
							<div class='topic gradient__text'>Email us at</div>
							<div class='text-one'>contact@squirrelverse.io</div>
							{/* <div class='text-two'>support@squirrel.solutions</div> */}
						</div>
					</div>
					<div class='right-side'>
						<div class='topic-text gradient__text'>Send us a message</div>
						<p className='madharchodp'>
							
						</p>
						<form onSubmit={sendEmail}>
							<div class='input-box'>
								<input type='text' placeholder='Enter your name' required />
							</div>
							<div class='input-box'>
								<input type='text' placeholder='Enter your email' required />
							</div>
							<div class='input-box message-box'>
								<textarea
									name=''
									placeholder='Enter you message'
									id=''
									cols='30'
									rows='10'
									required
									
								></textarea>
							</div>

							{/*<button class= 'button' type='button'>Send Now</button>*/}
							<div class='button'>
								{/*<input type='button' value='Send Now'/>*/}
								<button type='submit'>Send Now</button>
								{isOpen && <Modal setIsOpen={setIsOpen} />}
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
