import React from "react"
import Feature from "../../components/feature/Feature"
import whysq1 from "../../assets/whysq1.jpg"
import whysq2 from "../../assets/whysq2.jpg"
import whysq3 from "../../assets/whysq3.jpg"
import "./features.scss"

const featuresData = [
	{
		title: "Bridging Web2 and Web3",
		text: "Will you drastically shift to Web3? SquirrelVerse offers you solutions that combine tools from both Web2 and Web3. ",
	},
	{
		title: "Unique consulting framework",
		text: "Our distinguished consulting solutions provide you with the easiest Metaverse roadmap by understanding your true business needs.",
	},
	{
		title: "World’s 1st Enterprise Software",
		text: "SquirrelVerse uses the best elements of both Web2 and Web3 to bring Security, Scalability, and High Performance. ",
	},
]

const Features = () => (
	<div className='section__bg'>
		<div className='gpt3__features' id='features'>
			<div className='gpt3__features-heading'>
				<h1 className='gradient__text'>Bridging Web2 and Web3</h1>
				<p>
					Will you drastically shift to Web3? SquirrelVerse offers you solutions
					that combine tools from both Web2 and Web3.
				</p>
			</div>
			<div className='gpt3__features-container'>
				<img className='gpt3__features-container--img' src={whysq1} alt='' />
			</div>
		</div>
		<div className='gpt3__features gpt3__features-2' id='features'>
			<div className='gpt3__features-container'>
				<img className='gpt3__features-container--img' src={whysq2} alt='' />
			</div>
			<div className='gpt3__features-heading gpt3__features-heading-2'>
				<h1 className='gradient__text'>Unique consulting framework</h1>
				<p>
					Our distinguished consulting solutions provide you with the easiest
					Web3 roadmap by understanding your true business needs.
				</p>
			</div>
		</div>
		<div className='gpt3__features' id='features'>
			<div className='gpt3__features-heading'>
				<h1 className='gradient__text'>World’s 1st Enterprise Software</h1>
				<p>
					SquirrelVerse uses the best elements of both Web2 and Web3 to bring
					Security, Scalability, and High Performance.
				</p>
			</div>
			<div className='gpt3__features-container'>
				<img className='gpt3__features-container--img' src={whysq3} alt='' />
			</div>
		</div>
	</div>
)

export default Features
