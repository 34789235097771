import React from "react"
import Whymatter from "../../assets/Whymatter.webp"
import "./WhyMatter.scss"

const WhyMatter = () => {
	return (
		<section className='whymatter'>
			<div className='whymatter__right '>
				<h1 className='whymatter__right--title gradient__text'>
					Web3 is a big opportunity for brands.
				</h1>
				<p className='whymatter__right--text'>
					While it is the future of how brands and consumers will interact will
					each other, it is important for brands to make their audience aware of
					the transformation happening. Online, Web3 has been and is still being
					searched for and discussed by many people. The average number of
					participants in this conversation is gradually increasing, indicating
					that this is a long-lasting trend.
				</p>
				\
				<p className='whymatter__right--text'>
					With each passing day, more businesses and consumers are putting their
					faith in the Web3 technology and the early one can adopt it, the
					better it gets.					And with new innovations happening, brands are getting new
					opportunities and benefits. Consumer Brands are looking for new ways
					to enhance their consumer experience.
				</p>
				<p className='whymatter__right--text'>
					Digital Assets or NFTs have the ability to play a significant role in
					creating a unique client experience. They serve as a means of
					satisfying consumer wants and, as such, create a chance for new,
					attractive digital offerings, which may be crucial in increasing
					customer engagement.
				</p>
			</div>
			<div className='whymatter__left'>
				<img
					className='whymatter__left--img'
					src={Whymatter}
					alt='squirrel-logo'
				/>
			</div>
		</section>
	)
}

export default WhyMatter
